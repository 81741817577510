header,
footer {
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.3);
  height: 100vh;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  margin-top: 50px;
  box-shadow: -3px -5px 5px rgba(0, 0, 0, 0.3);
}
main {
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.3), -3px -5px 5px rgba(0, 0, 0, 0.3);
    height: 100vh;
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    /* padding: 50px;
    gap: 50px; */
    overflow: hidden;
  }
  
  main div {
    /* flex: 2; */
    width: 50%;
    height: 80%;
    border: 2px solid #999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: all ease-in 1s;
  }
  
  .slide-in {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
  main div:first-child {
    transform: translateX(-150%);
    opacity: 0;
  }
  main div:last-child {
    transform: translateX(150%);
    opacity: 0;
  }